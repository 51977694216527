import { SearchStockCarResponse } from 'api/driverama/stock/carDetail'
import { useTranslation } from 'react-i18next'

export type NotSellReason = NonNullable<
  SearchStockCarResponse['states']['reason']
>

export const notSellReasons = [
  'ADMIN_PROBLEM',
  'CLAIM',
  'COMPANY_CAR',
  'CRASH_AND_INSURANCE',
  'EXPORT_AURES',
  'EXPORT_B2B',
  'LEGAL_PROBLEM',
  'LONG_TIME_REPAIR',
  'VIP_RESERVATION'
] as const

type NotSellReasonsOption = {
  value: typeof notSellReasons[number]
  label: string
}

export function useNotSellReasons(): NotSellReasonsOption[] {
  const { t } = useTranslation(['core'])

  return [
    {
      label: t('core:car_states_not_sell_state_LONG_TIME_REPAIR'),
      value: 'LONG_TIME_REPAIR'
    },
    {
      label: t('core:car_states_not_sell_state_ADMIN_PROBLEM'),
      value: 'ADMIN_PROBLEM'
    },
    {
      label: t('core:car_states_not_sell_state_CRASH_AND_INSURANCE'),
      value: 'CRASH_AND_INSURANCE'
    },
    {
      label: t('core:car_states_not_sell_state_LEGAL_PROBLEM'),
      value: 'LEGAL_PROBLEM'
    },
    { label: t('core:car_states_not_sell_state_CLAIM'), value: 'CLAIM' },
    {
      label: t('core:car_states_not_sell_state_COMPANY_CAR'),
      value: 'COMPANY_CAR'
    },
    {
      label: t('core:car_states_not_sell_state_VIP_RESERVATION'),
      value: 'VIP_RESERVATION'
    },
    {
      label: t('core:car_states_not_sell_state_EXPORT_AURES'),
      value: 'EXPORT_AURES'
    },
    {
      label: t('core:car_states_not_sell_state_EXPORT_B2B'),
      value: 'EXPORT_B2B'
    }
  ]
}
