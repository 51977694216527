import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { media } from 'driverama-core/styles/media'

import { size } from 'driverama-core/styles/spacing'
import { color, radius, weight } from 'driverama-core/styles/variables'

export const SStatsWrapper = styled.div`
  margin: 0 ${size(8)} ${size(8)};

  max-width: 100%;

  @media ${media.lte('MD')} {
    display: none;
  }
`

export const SStateContainer = styled.div<{ statesCount: number }>`
  display: grid;

  grid-gap: ${size(2)};

  ${({ statesCount }) => css`
    grid-template-columns: repeat(${statesCount}, auto);
  `};
`

export const SStateCount = styled.span`
  color: ${color('night-l-100')};
  font-size: 18px;
  font-weight: ${weight('medium')};
`

export const SStateName = styled.span`
  color: inherit;
  font-size: 14px;
`

export const SState = styled.div<{ isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  border-radius: ${radius('corner-smaller')};

  cursor: pointer;

  height: ${size(16)};

  padding: ${size(2)} 0;

  ${({ isActive }) => {
    if (isActive) {
      return css`
        background-color: ${color('night-l-100')};

        ${SStateCount} {
          color: ${color('white')};
        }

        ${SStateName} {
          color: ${color('white')};
        }
      `
    }

    return css`
      background-color: ${color('night-l-700')};
    `
  }}
`
