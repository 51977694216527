import { useMedia } from 'react-use'

import { media } from 'driverama-core/styles/media'

export function useMediaVariant() {
  const isMobile = useMedia(media.lte('MD'))
  const isTablet = useMedia(media.lte('tablet'))

  const variant = isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'

  return variant
}
