import { SState, SStateCount, SStateName } from './Stats.styled'

type Props = {
  count?: number
  name?: string
  isActive: boolean
  onClick?: () => void
}

export function StatsItem({ count, name, isActive, onClick }: Props) {
  return (
    <SState onClick={onClick} isActive={isActive}>
      <SStateCount>{count}</SStateCount>
      <SStateName>{name}</SStateName>
    </SState>
  )
}
