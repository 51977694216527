import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { paths } from 'driverama-core/api/driverama/generated/stock'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { useQuery, UseQueryOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/fetcher'

export type CarReportingResponse = paths['/stock/stock-management/cars/statistics']['get']['responses']['200']['content']['application/com.driverama-v1+json']

type QueryOpts = UseQueryOptions<unknown, unknown, CarReportingResponse>

export function useCarsReporting(opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.carsReporting,
    async () => {
      const res = await apiAuthFetcher<CarReportingResponse>(
        URLS.carsReporting,
        {
          method: HTTPMethod.GET
        }
      )

      return res.json
    },
    opts
  )
}
