import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { CellProps } from 'react-table'

export function DefaultCell<T extends Record<string, unknown>>(
  props: PropsWithChildren<CellProps<T>>
) {
  const { t } = useTranslation(['core'])

  if (!props.value) {
    return <span>{t('core:nil_value')}</span>
  }

  return <span>{props.value}</span>
}
