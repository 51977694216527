import { useEffect, useState } from 'react'
import { useFiltersContext } from 'sections/filters/context/FiltersContext'
import { setFiltersForModule } from 'sections/filters/FilterHooks'
import { FilterModule } from 'sections/filters/Filters.utils'

import { LogisticStateObject, SellingStateObject } from './StatsTypes'

export function useStats({ stats }: { stats: SellingStateObject[] }) {
  const {
    state: { stockManager },
    dispatch
  } = useFiltersContext()

  const managerFilters = stockManager.filters

  const [activeSellingState, setActiveSellingState] = useState<
    SellingStateObject | undefined
  >()
  const [activeLogisticState, setActiveLogisticstate] = useState<
    LogisticStateObject | undefined
  >()

  useEffect(() => {
    // sync Selling state in filters with Stats component
    if (managerFilters.sellingStates?.length === 1) {
      const filterSellingState = managerFilters.sellingStates?.[0]

      setActiveSellingState(stats.find(i => i.state === filterSellingState))
    } else {
      setActiveSellingState(undefined)
    }

    // sync Logicstic state in filters with Stats component
    if (managerFilters.states?.length === 1 && activeSellingState) {
      const filtersLogisticState = managerFilters.states?.[0]

      setActiveLogisticstate(
        activeSellingState.subRows?.find(i => i.state === filtersLogisticState)
      )
    } else {
      setActiveLogisticstate(undefined)
    }
  }, [
    stats,
    activeSellingState,
    managerFilters.states,
    managerFilters.sellingStates
  ])

  const handleChangeActiveSellingState = (state?: SellingStateObject) => {
    if (state === undefined) {
      // reset logistic state and selling state filters
      dispatch(
        setFiltersForModule(FilterModule.MANAGER, {
          sellingStates: undefined,
          states: undefined
        })
      )
    } else {
      if (state.state) {
        dispatch(
          setFiltersForModule(FilterModule.MANAGER, {
            sellingStates: [state.state],
            states: undefined
          })
        )
      }
    }
    // reset active logistic state
    setActiveLogisticstate(undefined)

    setActiveSellingState(state)
  }

  const handleChangeActiveLogisticState = (state: LogisticStateObject) => {
    if (state.state) {
      dispatch(
        setFiltersForModule(FilterModule.MANAGER, { states: [state.state] })
      )
    }

    setActiveLogisticstate(state)
  }

  return {
    activeSellingState,
    activeLogisticState,
    handleChangeActiveSellingState,
    handleChangeActiveLogisticState
  }
}
