import { useCarsReporting } from 'api/driverama/stock/carsReporting'
import { Flex } from 'driverama-core/components/Flex'
import { useModal } from 'driverama-core/components/modal/Modal'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import {
  TextBody,
  TextBodyMedium,
  TextHeader
} from 'driverama-core/components/text/Text'
import { Tooltip } from 'driverama-core/components/tooltip/Tooltip'
import { size } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'
import IconCircleInfo from 'images/icons/IconCircleInfo.svg'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { SButton } from './CarDataTableReporting.styled'
import { useCarsReportingTooltips } from './CarDataTableReporting.utils'
import { ReportingTooltip } from './ReportingTooltip'

type ReportingModalRowProps = {
  label: string
  value: number
  tooltip: ReactNode
}

export function CarDataTableReporting() {
  const { t } = useTranslation('core')
  const { openReportingModal, reportingModal } = useReportingModal()

  return (
    <>
      <SButton variant="primary" onClick={openReportingModal}>
        {t('car_table_reporting_button')}
      </SButton>

      {reportingModal}
    </>
  )
}

function ReportingModalRow({ label, value, tooltip }: ReportingModalRowProps) {
  return (
    <Flex variant="row" justify="between" css={{ padding: '6px 0' }}>
      <Flex variant="row">
        <TextBody>{label}</TextBody>
        <Spacer size={5} axis="horizontal" />

        <Tooltip
          placement="top"
          content={tooltip}
          css={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <IconCircleInfo
            width={size(5)}
            height={size(5)}
            color={color('night-l-200')}
            css={{ marginLeft: -10 }}
          />
        </Tooltip>
      </Flex>

      <TextBodyMedium color="night-l-100">{value}</TextBodyMedium>
    </Flex>
  )
}

function ReportingModal() {
  const { t } = useTranslation('core')
  const { isLoading, data } = useCarsReporting()
  const tooltips = useCarsReportingTooltips()

  function renderContent() {
    if (data) {
      return (
        <Flex variant="column">
          <ReportingModalRow
            label={t('car_table_reporting_modal_all')}
            value={data.allStockCarsCount}
            tooltip={<ReportingTooltip {...tooltips['allStockCarsCount']} />}
          />
          <ReportingModalRow
            label={t('car_table_reporting_modal_wcs')}
            value={data.workingCapitalStockCarsCount}
            tooltip={
              <ReportingTooltip {...tooltips['workingCapitalStockCarsCount']} />
            }
          />
          <ReportingModalRow
            label={t('car_table_reporting_modal_web_offer')}
            value={data.webOfferCarsCount}
            tooltip={<ReportingTooltip {...tooltips['webOfferCarsCount']} />}
          />
          <ReportingModalRow
            label={t('car_table_reporting_modal_web_stock')}
            value={data.webStockCarsCount}
            tooltip={<ReportingTooltip {...tooltips['webStockCarsCount']} />}
          />
          <ReportingModalRow
            label={t('car_table_reporting_modal_stock')}
            value={data.stockCarsCount}
            tooltip={<ReportingTooltip {...tooltips['stockCarsCount']} />}
          />
          <ReportingModalRow
            label={t('car_table_reporting_modal_not_to_sell')}
            value={data.stockNotToSellCarsCount}
            tooltip={
              <ReportingTooltip {...tooltips['stockNotToSellCarsCount']} />
            }
          />
        </Flex>
      )
    }

    return null
  }

  return (
    <Flex variant="column">
      <TextHeader variant={['h5', 'h4']}>
        {t('car_table_reporting_modal_title')}
      </TextHeader>
      <Spacer size={6} />

      {isLoading ? <SpinnerCentered /> : renderContent()}
    </Flex>
  )
}

function useReportingModal() {
  const { t } = useTranslation('core')

  const [reportingModal, openReportingModal] = useModal(
    () => <ReportingModal />,
    {
      closeLabel: t('close')
    }
  )

  return {
    reportingModal,
    openReportingModal
  }
}
