import { useTranslation } from 'react-i18next'

import { useSellingStatesCarCount } from 'api/driverama/stock/statistics'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import { Spacer } from 'driverama-core/components/spacer/Spacer'

import { StatsItem } from './StatsItem'
import { SStateContainer, SStatsWrapper } from './Stats.styled'
import { useStats } from './StatsHook'

export function Stats() {
  const { t } = useTranslation(['core'])

  const { stats, total, isLoading } = useSellingStatesCarCount()

  const {
    activeSellingState,
    activeLogisticState,
    handleChangeActiveSellingState,
    handleChangeActiveLogisticState
  } = useStats({ stats })

  // all states + "Total"
  const statesDisplayed = stats.length + 1

  if (isLoading) {
    return (
      <SStatsWrapper>
        <SpinnerCentered />
      </SStatsWrapper>
    )
  }

  return (
    <SStatsWrapper>
      <SStateContainer statesCount={statesDisplayed}>
        <StatsItem
          name={t('core:statistics_state_count_table_total')}
          count={total}
          isActive={activeSellingState?.state === undefined}
          onClick={() => handleChangeActiveSellingState(undefined)}
        />

        {stats.map(mr => (
          <StatsItem
            key={mr.state}
            onClick={() => handleChangeActiveSellingState(mr)}
            isActive={activeSellingState?.state === mr.state}
            count={mr.carsCount}
            name={mr.stateLabel}
          />
        ))}
      </SStateContainer>

      <Spacer size={2} />

      {!!activeSellingState?.subRows && (
        <SStateContainer statesCount={activeSellingState.subRows.length}>
          {activeSellingState.subRows.map(sr => (
            <StatsItem
              key={sr.state}
              isActive={activeLogisticState?.state === sr.state}
              onClick={() => handleChangeActiveLogisticState(sr)}
              name={sr.stateLabel}
              count={sr.carsCount}
            />
          ))}
        </SStateContainer>
      )}
    </SStatsWrapper>
  )
}
