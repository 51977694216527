import styled from '@emotion/styled'
import { Button } from 'driverama-core/components/button/Button'
import { Flex } from 'driverama-core/components/Flex'
import { TextBody } from 'driverama-core/components/text/Text'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'

export const SButton = styled(Button)`
  && {
    padding: ${size(3)} ${size(12)};

    @media ${media.lte('MD')} {
      flex: 1;
    }
  }
`

export const SUnderlinedText = styled(TextBody)`
  text-decoration: underline;
`

export const STooltipWrapper = styled(Flex)`
  min-width: ${size(50)};
`
