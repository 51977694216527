import { css } from '@emotion/react'
import { CarTableData, useSearchCar } from 'api/driverama/stock/carSearch'
import { QrCodeModal } from 'components/qrCode/QrCodeModal'
import { InfiniteTable } from 'components/table/InfiniteTable'
import { createSortArray } from 'components/table/sort/SortableColumn.utils'
import { useModal } from 'driverama-core/components/modal/Modal'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import { size } from 'driverama-core/styles/spacing'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'react-table'
import { useFiltersContext } from 'sections/filters/context/FiltersContext'
import { useMediaVariant } from 'utils/useMediaVariant'
import { SCarDataTableContainer } from './CarDataTable.styled'
import { useTableColumns } from './CarDataTable.utils'
import { CarDataTableOverview } from './overview/CarDataTableOverview'

const ADD_DATA_KEY = 50

export function CarDataTable() {
  const { t } = useTranslation(['core'])

  const {
    state: { stockManager }
  } = useFiltersContext()

  const [selectedRow, setRow] = useState<Row<CarTableData>>()

  const { filters, sorting } = stockManager

  const statsOpened =
    !!filters['sellingStates']?.length || !!filters['states']?.length

  const {
    cars,
    isLoading,
    hasNextPage,
    fetchNextPage,
    totalResults
  } = useSearchCar(filters, {
    size: ADD_DATA_KEY,
    sort: createSortArray(sorting)
  })

  const mediaQuery = useMediaVariant()

  const heightCorrection =
    mediaQuery !== 'mobile' ? (statsOpened ? 162 : 97) : undefined

  const [qrModal, openQrModal] = useModal(
    () => selectedRow && <QrCodeModal row={selectedRow} />,
    {
      closeLabel: t('core:qr_code_modal_close'),
      wrapperStyles: css`
        padding: ${size(10)};
        width: ${size(120)};
        height: ${size(150)};
      `
    }
  )

  const handleQrModal = (row: Row<CarTableData>) => {
    setRow(row)
    openQrModal()
  }

  const { columns } = useTableColumns({ openQrModal: handleQrModal })

  return (
    <>
      <CarDataTableOverview results={totalResults} />
      <SCarDataTableContainer heightCorrection={heightCorrection}>
        {isLoading ? (
          <SpinnerCentered css={{ height: '100%' }} />
        ) : (
          <InfiniteTable
            columns={columns}
            data={cars}
            hasMoreRecords={(hasNextPage && cars.length !== 0) || false}
            loadMoreRecords={fetchNextPage}
          />
        )}

        {qrModal}
      </SCarDataTableContainer>
    </>
  )
}
