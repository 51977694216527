import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'

import Link from 'next/link'

export const SCarDataTableContainer = styled.div<{ heightCorrection?: number }>`
  flex: 1;
  min-width: 0;

  #scrollableDiv {
    ${({ heightCorrection = 0 }) => css`
      height: calc(
        100vh - var(--header-height) - var(--margin-vertical) -
          ${heightCorrection}px - var(--table-overview) -
          var(--car-table-header)
      );

      @media ${media.lte('tablet')} {
        height: calc(
          100vh - var(--header-height) - var(--mobile-search-height) -
            var(--margin-vertical) - ${heightCorrection}px -
            var(--mobile-bottom-menu-height) - var(--table-overview)
        );
      }

      @media ${media.lte('MD')} {
        height: calc(
          100vh - var(--header-height) - var(--mobile-search-height) -
            var(--mobile-bottom-menu-height) - var(--table-overview) -
            var(--car-table-header)
        );
      }

      @media ${media.lte('mobile')} {
        height: calc(
          100vh - var(--header-height) - var(--mobile-search-height) -
            var(--mobile-bottom-menu-height) - ${size(17)} -
            var(--table-overview)
        );
      }
    `};
  }
`

export const SLink = styled(Link)`
  text-decoration: underline;
  @media (hover: hover) {
    &:hover {
      cursor: help;
    }
  }
`
