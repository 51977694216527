import { operations } from 'driverama-core/api/driverama/generated/lov'
import { URLS } from 'driverama-core/constants/api'
import { QUERY_KEYS } from 'driverama-core/constants/queryKeys'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { Maybe } from 'driverama-core/utils/types'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { lovQueryOptions } from './lov.utils'

export type LovEnginesSearchResponse = operations['getEngines']['responses']['200']['content']['application/com.driverama-v1+json']
export type LovEngine = LovEnginesSearchResponse['content'][number]
type QueryBody = operations['getEngines']['requestBody']['content']['application/json']
type QueryOpts = UseQueryOptions<unknown, unknown, LovEnginesSearchResponse>

function getEngineLabel(engine: LovEngine) {
  return [engine.name, engine.powerKw && `${engine.powerKw}kW`]
    .filter(Boolean)
    .join(' ')
}

export async function fetchLovEngines(body: QueryBody = {}) {
  const res = await apiFetcher<LovEnginesSearchResponse>(
    URLS.lovEnginesSearch,
    { method: HTTPMethod.POST, body }
  )

  return res.json
}

export function useLovEnginesSearchQuery(
  body: QueryBody = {},
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.lovEnginesSearch(body),
    async () => fetchLovEngines(body),
    { ...lovQueryOptions, ...opts }
  )
}

type EngineListArgs = {
  modelIds: Maybe<string[]>
  fuelTypeIds: Maybe<string[]>
  year?: Maybe<number>
  ids?: string[]
  volumeCcmFrom?: Maybe<number>
  volumeCcmTo?: Maybe<number>
}

export function useEngineList(args: EngineListArgs, enabled?: boolean) {
  const { fuelTypeIds, modelIds, year, ids, volumeCcmFrom, volumeCcmTo } = args

  const { data, ...rest } = useLovEnginesSearchQuery(
    {
      filter: {
        ids: ids ?? [],
        modelIds: modelIds ?? undefined,
        fuelTypeIds: fuelTypeIds ?? undefined,
        yearFromTo: year ?? undefined,
        yearFromIncludeNull: true,
        yearToFrom: year ?? undefined,
        yearToIncludeNull: true,
        active: true,
        volumeCcmFrom: volumeCcmFrom ?? undefined,
        volumeCcmTo: volumeCcmTo ?? undefined
      }
    },
    {
      enabled:
        (!!modelIds && !!fuelTypeIds) || !!(ids && ids?.length > 0) || !!enabled
    }
  )

  const engines = useMemo(() => {
    return (
      data?.content
        .map(engine => ({
          value: engine.id,
          label: getEngineLabel(engine)
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) || []
    )
  }, [data])

  return { ...rest, engines }
}

export function getEngineDetailQueryBody(engineId: Maybe<string>): QueryBody {
  return {
    filter: {
      ids: engineId ? [engineId] : [],
      yearFromIncludeNull: true,
      active: true
    }
  }
}

export function useEngineDetailQuery(
  engineId: Maybe<string>,
  opts?: QueryOpts
) {
  const { data, ...rest } = useLovEnginesSearchQuery(
    getEngineDetailQueryBody(engineId),
    { enabled: !!engineId, ...opts }
  )

  const engine = useMemo(() => {
    const engineDto = data?.content.find(engine => engine.id === engineId)
    return engineDto
      ? {
          ...engineDto,
          label: getEngineLabel(engineDto)
        }
      : undefined
  }, [engineId, data])

  return { ...rest, data, engine }
}
