import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody } from 'driverama-core/components/text/Text'
import { useTranslation } from 'react-i18next'
import {
  STooltipWrapper,
  SUnderlinedText
} from './CarDataTableReporting.styled'
import { Tooltip } from './CarDataTableReporting.utils'

export function ReportingTooltip({
  title,
  includingStates,
  excludingStates
}: Tooltip) {
  const { t } = useTranslation(['core'])

  return (
    <STooltipWrapper variant="column">
      {Array.isArray(title) ? (
        title.map(singleTitle => (
          <SUnderlinedText size="small" color="white" key={singleTitle}>
            {singleTitle}
          </SUnderlinedText>
        ))
      ) : (
        <SUnderlinedText size="small" color="white">
          {title}
        </SUnderlinedText>
      )}

      {includingStates.length > 0 && (
        <>
          <Spacer size={4} />

          <SUnderlinedText size="small" color="white">
            {t('core:car_table_reporting_modal_tooltip_including_states')}
          </SUnderlinedText>
          {includingStates.map(includingState => (
            <TextBody size="small" key={includingState} color="white">
              {includingState}
            </TextBody>
          ))}
        </>
      )}

      {excludingStates.length > 0 && (
        <>
          <Spacer size={4} />

          <SUnderlinedText size="small" color="white">
            {t('core:car_table_reporting_modal_tooltip_excluding_states')}
          </SUnderlinedText>
          {excludingStates.map(excludingState => (
            <TextBody size="small" key={excludingState} color="white">
              {excludingState}
            </TextBody>
          ))}
        </>
      )}
    </STooltipWrapper>
  )
}
