import styled from '@emotion/styled'
import { Flex } from 'driverama-core/components/Flex'
import { TextHeader } from 'driverama-core/components/text/Text'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'

export const SWrapper = styled(Flex)`
  padding: ${size(6)} ${size(8)};
  justify-content: space-between;

  @media ${media.lte('MD')} {
    padding: ${size(1)} ${size(4)} ${size(4)};
    background-color: ${color('night-l-700')};
    justify-content: flex-end;
  }
`

export const STextHeader = styled(TextHeader)`
  @media ${media.lte('MD')} {
    display: none;
  }
`
