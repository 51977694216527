import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { operations } from 'driverama-core/api/driverama/generated/stock'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, UseQueryOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/fetcher'
import { getLogisticStateLabel } from 'utils/useLogisticStates'
import { getSellingStateLabelOrdered } from './../../../utils/useSellingStates'

export type SalesStatesStatsResponse = operations['getCarsStatesStats']['responses']['200']['content']['application/com.driverama-v1+json']

type QueryOpts = UseQueryOptions<unknown, unknown, SalesStatesStatsResponse>

export function useStatesStatsQuery(opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.getSalesStatesStats(),
    async () => {
      const res = await apiAuthFetcher<SalesStatesStatsResponse>(
        URLS.getSalesStatesStats,
        {
          method: HTTPMethod.GET
        }
      )

      return res.json
    },
    opts
  )
}

export function useSellingStatesCarCount() {
  const { t } = useTranslation(['core'])

  const { data, isLoading } = useStatesStatsQuery({
    refetchOnWindowFocus: false
  })

  const stats = useMemo(() => {
    const sellStats = data?.map(singleStat => {
      const sellingStateLabeled =
        singleStat.sellingState &&
        getSellingStateLabelOrdered(singleStat.sellingState, t)

      return {
        state: singleStat.sellingState,
        stateLabel: sellingStateLabeled?.label,
        carsCount: singleStat.carsCount,
        isSellState: true,
        subRows: singleStat.breakupByStates?.map(item => {
          const locationState =
            item.state && getLogisticStateLabel(item.state, t)
          return {
            state: item.state,
            stateLabel: locationState ?? undefined,
            carsCount: item.carsCount
          }
        }),
        order: sellingStateLabeled?.order
      }
    })

    return sellStats?.sort((a, b) => (a.order || 0) - (b.order || 0)) || []
  }, [t, data])

  const total = useMemo(
    () => data?.reduce((a, b) => a + (b.carsCount || 0), 0),
    [data]
  )

  return { stats, total, isLoading }
}
