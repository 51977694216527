import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CellProps, Column, Row } from 'react-table'

import { CarTableData } from 'api/driverama/stock/carSearch'

import { VinHeaderCell } from 'components/table/cells/VinHeaderCell'

import { size } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'

import IconQrCode from 'images/icons/IconQrCode.svg'

import { getLogisticStateLabel } from 'utils/useLogisticStates'
import { getSellingStateLabel } from 'utils/useSellingStates'

import { DefaultCell } from 'components/table/cells/DefaultCell'
import { parseUTCDate } from 'driverama-core/utils/date'
import { getEcomWebLink } from 'utils/getEcomWebLink'
import { SLink } from './CarDataTable.styled'

interface TableColumnsProps {
  openQrModal: (row: Row<CarTableData>) => void
}

function LogisticsStateCell(props: CellProps<CarTableData>) {
  const { t } = useTranslation(['core'])
  const { states } = props.row.original

  if (!states) {
    return null
  }

  const logisticState = getLogisticStateLabel(
    states.logisticState,
    t,
    states.reason
  )

  return logisticState
}

function SellingStateCell(props: CellProps<CarTableData>) {
  const { t } = useTranslation(['core'])
  const { states } = props.row.original

  if (!states) {
    return null
  }

  const sellingState = getSellingStateLabel(states.sellingState, t)

  return sellingState
}

function UpdatedAtCell(props: CellProps<CarTableData>) {
  const { t } = useTranslation(['core'])
  const { states } = props.row.original

  if (!states) {
    return null
  }

  return t('core:date_time_format', { date: parseUTCDate(states.updatedAt) })
}

export function useTableColumns({ openQrModal }: TableColumnsProps) {
  const { t } = useTranslation(['core'])

  const columns: Column<CarTableData>[] = useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id'
      },
      {
        Header: t('core:table_column_stock_no'),
        accessor: 'stockNo',
        width: 80,
        Cell: props => {
          const carData = props.row.original

          return (
            <SLink passHref href={getEcomWebLink(carData)}>
              <a
                target="_blank"
                onClick={e => {
                  e.stopPropagation()
                }}
              >
                {props.value}
              </a>
            </SLink>
          )
        }
      },
      {
        Header: t('core:table_column_qr'),
        accessor: 'qrCodeFileId',
        width: 40,
        Cell: props => (
          <IconQrCode
            style={{
              width: size(4),
              height: size(4),
              marginTop: size(1),
              cursor: 'help',
              color: color('night-l-100')
            }}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              openQrModal(props.row)
            }}
          />
        )
      },
      {
        Header: VinHeaderCell,
        accessor: 'vin',
        width: 180,
        Cell: DefaultCell
      },
      {
        Header: t('core:table_column_make_and_model'),
        accessor: 'modelMakeName',
        width: 150,
        Cell: DefaultCell
      },
      {
        Header: t('core:table_column_body'),
        accessor: 'body',
        width: 120
      },
      {
        Header: t('core:table_column_selling_state'),
        Cell: SellingStateCell,
        width: 150
      },

      {
        Header: t('core:table_column_logistic_state'),
        Cell: LogisticsStateCell,
        width: 200
      },
      {
        Header: t('core:table_column_states_updated_at'),
        Cell: UpdatedAtCell,
        width: 150
      },
      {
        Header: t('core:table_column_location'),
        accessor: 'location',
        width: 130,
        Cell: DefaultCell
      },
      {
        Header: t('core:table_column_synced_to_aggregator'),
        accessor: 'syncedToAnyAggregator',
        Cell: props => <span>{props.value ? t('core:yes') : t('core:no')}</span>
      },
      {
        Header: t('core:table_column_purchase_completed'),
        accessor: 'purchaseDealCompleted',
        Cell: props => <span>{props.value ? t('core:yes') : t('core:no')}</span>
      },
      {
        Header: t('core:table_column_price'),
        accessor: 'price',
        width: 75,
        Cell: props => (
          <span>
            {props.value
              ? t('core:price', { price: props.value })
              : t('core:nil_value')}
          </span>
        )
      },
      {
        Header: t('core:table_column_fuel'),
        accessor: 'fuelType',
        width: 80,
        Cell: DefaultCell
      },
      {
        Header: t('core:table_column_km'),
        accessor: 'actualMileageKm',
        Cell: props => t('core:km_format', { km: props.value })
      },
      {
        Header: t('core:table_column_year'),
        accessor: 'yearOfMake',
        width: 60,
        Cell: DefaultCell
      },
      {
        Header: t('core:table_column_transmission'),
        accessor: 'transmission',
        Cell: DefaultCell
      },
      {
        Header: t('core:table_column_source'),
        accessor: 'source',
        Cell: DefaultCell
      },
      {
        Header: t('core:table_column_source_country'),
        accessor: 'sourceCountry',
        width: 60,
        Cell: DefaultCell
      }
    ],
    [openQrModal, t]
  )

  return { columns }
}
