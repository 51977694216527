import styled from '@emotion/styled'

import { size } from 'driverama-core/styles/spacing'
import { color, radius, weight } from 'driverama-core/styles/variables'

export const SDisabledValueChip = styled.div`
  padding: ${size(1)} ${size(2)};
  background-color: ${color('sun-l-200')};

  color: ${color('sun-d-500')};
  font-weight: ${weight('bold')};
  font-size: ${size(3)};
  letter-spacing: 1px;
  text-transform: uppercase;

  border-radius: ${radius('corner-smallest')};
`
