import styled from '@emotion/styled'
import {
  color,
  radius,
  shadow,
  time,
  zIndex
} from 'driverama-core/styles/variables'
import { ButtonTransparent } from 'driverama-core/components/button/Button'
import { size } from 'driverama-core/styles/spacing'

const SIZES = {
  smaller: {
    size: size(8),
    iconSize: size(4)
  },
  normal: {
    size: size(12),
    iconSize: size(6)
  }
}

type SizeKey = keyof typeof SIZES

export const STrack = styled.div<{ size: SizeKey }>`
  --size: ${p => SIZES[p.size].size};
  --icon-size: ${p => SIZES[p.size].iconSize};

  position: relative;

  height: var(--size);
  display: flex;

  border-radius: ${radius('full')};
  background-color: ${color('almond-l-200')};
`

export const SOption = styled(ButtonTransparent)<{
  isActive: boolean
}>`
  position: relative;

  width: var(--size);
  height: var(--size);

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: ${zIndex('order-2')};

    width: var(--icon-size);
    height: var(--icon-size);

    color: ${({ isActive }) => color(isActive ? 'night-l-100' : 'night-text')};
  }
`

export const SSlider = styled.div<{ index: number }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zIndex('order-1')};

  width: var(--size);
  height: var(--size);

  background-color: ${color('white')};
  border-radius: ${radius('full')};
  box-shadow: ${shadow(1)};

  pointer-events: none;
  transition: all ${time('control')} ease-in-out;
  ${({ index }) => `transform: translateX(calc(var(--size) * ${index}))`};
`
