import { css } from '@emotion/react'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import { Flex } from 'driverama-core/components/Flex'
import { IconSwitch } from 'driverama-core/components/iconSwitch/IconSwitch'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody, TextBodyBold } from 'driverama-core/components/text/Text'
import { color } from 'driverama-core/styles/variables'

import IconCheck from 'images/icons/IconCheck.svg'

import { FilterModule, SourceEnum } from 'sections/filters/Filters.utils'

import { useFiltersContext } from 'sections/filters/context/FiltersContext'
import { setFiltersForModule } from 'sections/filters/FilterHooks'
import { SWrapper } from './CarDataTableOverview.styled'

type Props = {
  results?: number
}

function EmptyIcon() {
  return <></>
}

export function CarDataTableOverview({ results }: Props) {
  const { t } = useTranslation(['core'])
  const {
    state: { stockManager },
    dispatch
  } = useFiltersContext()

  const sourcesFilterValue = stockManager.filters.sources

  const onlyDriveramaCars =
    !!sourcesFilterValue?.length &&
    sourcesFilterValue.every(source => source === SourceEnum.DRIVERAMA)

  const handleChange = (value: boolean) => {
    if (value) {
      dispatch(
        setFiltersForModule(FilterModule.MANAGER, {
          sources: [SourceEnum.DRIVERAMA]
        })
      )
    } else {
      dispatch(
        setFiltersForModule(FilterModule.MANAGER, {
          sources: [SourceEnum.DRIVERAMA, SourceEnum.AURES]
        })
      )
    }
  }

  return (
    <AnimatePresence>
      <SWrapper
        transition={{
          duration: 0.3
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Flex variant="row">
          {results !== undefined && (
            <TextBody variant="caption">
              {t('core:table_results', {
                count: results
              })}
            </TextBody>
          )}
        </Flex>

        <Flex variant="row" align="center">
          <Spacer size={3} />

          <TextBodyBold variant="caption">
            {t('core:table_driverama_cars_only')}
          </TextBodyBold>
          <Spacer size={2} />
          <IconSwitch
            sliderStyles={css`
              background-color: ${onlyDriveramaCars
                ? color('night-l-100')
                : color('white')};
            `}
            buttonStyles={css`
              & svg {
                color: ${onlyDriveramaCars
                  ? color('white')
                  : color('night-text')};
              }
            `}
            size="smaller"
            value={onlyDriveramaCars}
            options={[
              { value: false, icon: EmptyIcon },
              { value: true, icon: IconCheck }
            ]}
            onChange={handleChange}
          />
        </Flex>
      </SWrapper>
    </AnimatePresence>
  )
}
