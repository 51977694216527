import { isMultiValue } from 'components/form/select/FormSelect'
import { useBodiesSearchList } from 'driverama-core/api/driverama/lov/lovBodiesSearch'
import { useActiveBranchList } from 'driverama-core/api/driverama/lov/lovBranchesSearch'
import { useEngineList } from 'driverama-core/api/driverama/lov/lovEnginesSearch'
import { useFuelTypeList } from 'driverama-core/api/driverama/lov/lovFuelTypes'
import { useMakeList } from 'driverama-core/api/driverama/lov/lovMakes'
import { useModelList } from 'driverama-core/api/driverama/lov/lovModelsSearch'
import { useTransmissionList } from 'driverama-core/api/driverama/lov/lovTransmissions'
import { useTranslation } from 'react-i18next'
import { useSellingStateSelectOptions } from 'sections/carDetail/carInfoHeader/CarInfoHeader.utils'
import { useFiltersContext } from 'sections/filters/context/FiltersContext'
import {
  useBooleanFilter,
  useMultiValueFilter,
  useRangeInputs
} from 'sections/filters/FilterHooks'
import {
  FilterModule,
  SourceCountryEnum,
  SourceEnum
} from 'sections/filters/Filters.utils'
import { useLogisticStatesLabelResolver } from 'utils/useLogisticStates'
import { useNotSellReasons } from 'utils/useNotSellReasons'

const MANAGER_FILTER_MODULE = FilterModule.MANAGER

export function useCarDataTableFilters() {
  const { t } = useTranslation(['core'])

  const {
    state: { stockManager }
  } = useFiltersContext()

  const managerFilters = stockManager.filters

  const { carMakes } = useMakeList()
  const carMakeOptions = carMakes.map(cm => ({
    label: cm.name || cm.id,
    value: cm.id
  }))

  const { models } = useModelList({ carMakeIds: managerFilters?.makeIds }, true)
  const modelOptions = models.map(m => ({ label: m.name || m.id, value: m.id }))

  const { bodies } = useBodiesSearchList(
    managerFilters?.modelIds ?? undefined,
    managerFilters?.makeIds ?? undefined
  )

  const { fuelTypes } = useFuelTypeList()
  const { engines } = useEngineList({
    fuelTypeIds: managerFilters?.fuelIds,
    modelIds: managerFilters?.modelIds,
    ids: []
  })
  const { transmissions } = useTransmissionList()
  const { branches } = useActiveBranchList()

  const sources = Object.values(SourceEnum).map(source => ({
    value: source,
    label: source
  }))

  const sourceCountries = Object.values(SourceCountryEnum).map(sc => ({
    value: sc,
    label: sc
  }))

  const sellingStateOptions = useSellingStateSelectOptions()
  const logisticStatesResolver = useLogisticStatesLabelResolver()
  const reasonsOptions = useNotSellReasons()

  const makeFilter = useMultiValueFilter({
    module: MANAGER_FILTER_MODULE,
    name: 'makeIds',
    label: t('core:left_menu_filter_make'),
    options: carMakeOptions
  })

  const modelFilter = useMultiValueFilter({
    module: MANAGER_FILTER_MODULE,
    name: 'modelIds',
    label: t('core:left_menu_filter_model'),
    options: modelOptions
  })

  const bodyFilter = useMultiValueFilter({
    module: MANAGER_FILTER_MODULE,
    name: 'bodyIds',
    label: t('core:left_menu_filter_car_body'),
    options: bodies
  })

  const fuelTypeFilter = useMultiValueFilter({
    module: MANAGER_FILTER_MODULE,
    name: 'fuelIds',
    label: t('core:left_menu_filter_fuel_type'),
    options: fuelTypes
  })

  const engineFilter = useMultiValueFilter({
    module: MANAGER_FILTER_MODULE,
    name: 'engineIds',
    label: t('core:left_menu_filter_engine'),
    options: engines
  })

  const transsmissionFilter = useMultiValueFilter({
    module: MANAGER_FILTER_MODULE,
    name: 'transmissionIds',
    label: t('core:left_menu_filter_gear'),
    options: transmissions
  })

  const branchFilter = useMultiValueFilter({
    module: MANAGER_FILTER_MODULE,
    name: 'branchIds',
    label: t('core:left_menu_filter_branch'),
    options: branches
  })

  const sourceFilter = useMultiValueFilter({
    module: MANAGER_FILTER_MODULE,
    name: 'sources',
    label: t('core:left_menu_filter_source'),
    options: sources
  })

  const sourceCountryFilter = useMultiValueFilter({
    module: MANAGER_FILTER_MODULE,
    name: 'sourceCountries',
    label: t('core:left_menu_filter_source_country'),
    options: sourceCountries
  })

  const sellingStateFilter = useMultiValueFilter({
    module: MANAGER_FILTER_MODULE,
    name: 'sellingStates',
    label: t('core:left_menu_filter_selling_state'),
    options: sellingStateOptions
  })

  const logisticStateFilter = useMultiValueFilter({
    module: MANAGER_FILTER_MODULE,
    name: 'states',
    label: t('core:left_menu_filter_logistic_state'),
    options: logisticStatesResolver(),
    modifyOtherFiltersOnChange: newValue => {
      if (isMultiValue(newValue)) {
        const isNotSellFiltering = newValue.find(
          filter => filter.value === 'NOT_SELL'
        )

        if (!isNotSellFiltering) {
          return { reasons: undefined }
        }
      }

      return
    }
  })

  const reasonsFilter = useMultiValueFilter({
    module: MANAGER_FILTER_MODULE,
    name: 'reasons',
    label: t('core:left_menu_filter_reasons'),
    options: reasonsOptions,
    displayed: managerFilters?.states?.includes('NOT_SELL') ?? false
  })

  const dropdowns = [
    makeFilter,
    modelFilter,
    bodyFilter,
    fuelTypeFilter,
    engineFilter,
    transsmissionFilter,
    branchFilter,
    sourceCountryFilter,
    sourceFilter,
    sellingStateFilter,
    logisticStateFilter,
    reasonsFilter
  ]

  const yearOfMakeRangeFilter = useRangeInputs({
    module: MANAGER_FILTER_MODULE,
    fromKey: 'yearOfMakeFrom',
    toKey: 'yearOfMakeTo',
    label: t('core:left_menu_filter_year')
  })

  const mileageRangeFilter = useRangeInputs({
    module: MANAGER_FILTER_MODULE,
    fromKey: 'actualMileageKmFrom',
    toKey: 'actualMileageKmTo',
    label: t('core:left_menu_filter_kilometers')
  })

  const priceRangeFilter = useRangeInputs({
    module: MANAGER_FILTER_MODULE,
    fromKey: 'priceFrom',
    toKey: 'priceTo',
    label: t('core:left_menu_filter_price'),
    adornment: '€'
  })

  const rangeInputs = [
    yearOfMakeRangeFilter,
    mileageRangeFilter,
    priceRangeFilter
  ]

  const syncedToAGFilter = useBooleanFilter({
    module: MANAGER_FILTER_MODULE,
    name: 'syncedToAnyAggregator',
    label: t('core:left_menu_filter_aggregator')
  })

  const purchaseCompletedFilter = useBooleanFilter({
    module: MANAGER_FILTER_MODULE,
    name: 'purchaseDealCompleted',
    label: t('core:left_menu_filter_purchase_completed')
  })

  const boolFilters = [purchaseCompletedFilter, syncedToAGFilter]

  return { dropdowns, rangeInputs, boolFilters }
}
