import { CarReportingResponse } from 'api/driverama/stock/carsReporting'
import { useTranslation } from 'react-i18next'

// add including and excluding states
export type Tooltip = {
  title: string | string[]
  includingStates: string[]
  excludingStates: string[]
}

function getTooltipReportingState(
  state1: string,
  state2: string,
  delimiter = '+'
) {
  return [state1, state2].join(` ${delimiter} `)
}

export function useCarsReportingTooltips(): Record<
  keyof CarReportingResponse,
  Tooltip
> {
  const { t } = useTranslation('core')

  return {
    allStockCarsCount: {
      title: t('car_table_reporting_modal_tooltip_dri_cars_only'),
      includingStates: [
        getTooltipReportingState(
          t('car_table_reporting_modal_tooltip_state_branch'),
          t('car_table_reporting_modal_tooltip_state_any_logistic_state')
        ),
        getTooltipReportingState(
          t('car_table_reporting_modal_tooltip_state_sell_prep'),
          t('car_table_reporting_modal_tooltip_state_any_logistic_state')
        ),
        getTooltipReportingState(
          t('car_table_reporting_modal_tooltip_state_selling'),
          t('car_table_reporting_modal_tooltip_state_any_logistic_state')
        ),
        getTooltipReportingState(
          t('car_table_reporting_modal_tooltip_state_sold'),
          t('car_table_reporting_modal_tooltip_state_any_logistic_state')
        )
      ],
      excludingStates: [
        getTooltipReportingState(
          getTooltipReportingState(
            t('car_table_reporting_modal_tooltip_state_any_selling_state'),
            t('car_table_reporting_modal_tooltip_state_not_sell')
          ),
          t('car_table_reporting_modal_tooltip_company_car'),
          '-'
        )
      ]
    },
    workingCapitalStockCarsCount: {
      title: [
        t('car_table_reporting_modal_tooltip_dri_cars_only'),
        t('car_table_reporting_modal_tooltip_bought_cars_only')
      ],
      includingStates: [
        getTooltipReportingState(
          t('car_table_reporting_modal_tooltip_state_branch'),
          t('car_table_reporting_modal_tooltip_state_any_logistic_state')
        ),
        getTooltipReportingState(
          t('car_table_reporting_modal_tooltip_state_sell_prep'),
          t('car_table_reporting_modal_tooltip_state_any_logistic_state')
        ),
        getTooltipReportingState(
          t('car_table_reporting_modal_tooltip_state_selling'),
          t('car_table_reporting_modal_tooltip_state_any_logistic_state')
        ),
        getTooltipReportingState(
          t('car_table_reporting_modal_tooltip_state_sold'),
          t('car_table_reporting_modal_tooltip_state_ordered')
        )
      ],
      excludingStates: [
        getTooltipReportingState(
          getTooltipReportingState(
            t('car_table_reporting_modal_tooltip_state_any_selling_state'),
            t('car_table_reporting_modal_tooltip_state_not_sell')
          ),
          t('car_table_reporting_modal_tooltip_company_car'),
          '-'
        )
      ]
    },
    webOfferCarsCount: {
      title: t('car_table_reporting_modal_tooltip_dri_and_aures_cars'),
      includingStates: [
        getTooltipReportingState(
          t('car_table_reporting_modal_tooltip_state_selling'),
          t('car_table_reporting_modal_tooltip_state_any_logistic_state')
        )
      ],
      excludingStates: [
        getTooltipReportingState(
          getTooltipReportingState(
            t('car_table_reporting_modal_tooltip_state_any_selling_state'),
            t('car_table_reporting_modal_tooltip_state_not_sell')
          ),
          t('car_table_reporting_modal_tooltip_any_reason')
        )
      ]
    },
    webStockCarsCount: {
      title: t('car_table_reporting_modal_tooltip_dri_cars_only'),
      includingStates: [
        getTooltipReportingState(
          t('car_table_reporting_modal_tooltip_state_selling'),
          t('car_table_reporting_modal_tooltip_state_any_logistic_state')
        )
      ],
      excludingStates: [
        getTooltipReportingState(
          getTooltipReportingState(
            t('car_table_reporting_modal_tooltip_state_any_selling_state'),
            t('car_table_reporting_modal_tooltip_state_not_sell')
          ),
          t('car_table_reporting_modal_tooltip_any_reason')
        )
      ]
    },
    stockCarsCount: {
      title: [
        t('car_table_reporting_modal_tooltip_dri_cars_only'),
        t('car_table_reporting_modal_tooltip_bought_cars_only')
      ],
      includingStates: [
        getTooltipReportingState(
          t('car_table_reporting_modal_tooltip_state_branch'),
          t('car_table_reporting_modal_tooltip_state_any_logistic_state')
        ),
        getTooltipReportingState(
          t('car_table_reporting_modal_tooltip_state_sell_prep'),
          t('car_table_reporting_modal_tooltip_state_any_logistic_state')
        ),
        getTooltipReportingState(
          t('car_table_reporting_modal_tooltip_state_selling'),
          t('car_table_reporting_modal_tooltip_state_any_logistic_state')
        )
      ],
      excludingStates: [
        getTooltipReportingState(
          getTooltipReportingState(
            t('car_table_reporting_modal_tooltip_state_any_selling_state'),
            t('car_table_reporting_modal_tooltip_state_not_sell')
          ),
          t('car_table_reporting_modal_tooltip_company_car'),
          '-'
        )
      ]
    },
    stockNotToSellCarsCount: {
      title: t('car_table_reporting_modal_tooltip_dri_cars_only'),
      includingStates: [
        getTooltipReportingState(
          getTooltipReportingState(
            t('car_table_reporting_modal_tooltip_state_selling'),
            t('car_table_reporting_modal_tooltip_state_not_sell')
          ),
          t('car_table_reporting_modal_tooltip_any_reason')
        )
      ],
      excludingStates: [
        getTooltipReportingState(
          getTooltipReportingState(
            t('car_table_reporting_modal_tooltip_state_selling'),
            t('car_table_reporting_modal_tooltip_state_not_sell')
          ),
          t('car_table_reporting_modal_tooltip_company_car'),
          '-'
        )
      ]
    }
  }
}
