import { useTranslation } from 'react-i18next'
import { CarDataTableReporting } from '../reporting/CarDataTableReporting'
import { STextHeader, SWrapper } from './CarTableHeader.styled'

export function CarTableHeader() {
  const { t } = useTranslation('core')
  return (
    <SWrapper variant="row" align="center">
      <STextHeader variant="h5">{t('car_table_header')}</STextHeader>
      <CarDataTableReporting />
    </SWrapper>
  )
}
