import { SOption, SSlider, STrack } from './IconSwitch.styled'
import { ComponentType } from 'react'
import { SerializedStyles } from '@emotion/react'

interface Props<T> {
  options: { value: T; icon: ComponentType }[]
  value: T
  onChange: (value: T) => void
  size?: 'smaller' | 'normal'
  sliderStyles?: SerializedStyles
  buttonStyles?: SerializedStyles
}

export function IconSwitch<T>({
  options,
  value,
  onChange,
  size = 'normal',
  sliderStyles,
  buttonStyles
}: Props<T>) {
  return (
    <STrack size={size}>
      <SSlider
        index={options.findIndex(option => option.value === value)}
        css={sliderStyles}
      />
      {options.map((option, i) => (
        <SOption
          key={i}
          isActive={option.value === value}
          onClick={() => onChange(option.value)}
          css={buttonStyles}
        >
          <option.icon />
        </SOption>
      ))}
    </STrack>
  )
}
