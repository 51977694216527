import styled from '@emotion/styled'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'
import { motion } from 'framer-motion'

export const SWrapper = styled(motion.div)`
  padding: 0 ${size(8)} ${size(6)};

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${media.lte('MD')} {
    background-color: ${color('night-l-700')};
  }
`
