import { Select } from 'components/select/Select'
import { SelectOption, SelectProps } from 'components/select/Select.utils'
import { Flex } from 'driverama-core/components/Flex'
import { TextBodyBold } from 'driverama-core/components/text/Text'
import { Control, Controller } from 'react-hook-form'
import { MultiValue, SingleValue } from 'react-select'
import { SDisabledValueChip } from './FormSelect.styled'

export type SelectFieldProps = {
  type: 'select'
  isMulti: boolean
  withoutLabel?: boolean
  displaySelectWhenDisabled?: boolean
} & Omit<SelectProps, 'label' | 'name' | 'disabled' | 'onChange'>

type Props = {
  control: Control
  label: string
  name: string
  disabled: boolean
} & SelectFieldProps

export function FormSelect(props: Props) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => {
        if (props.disabled && !props.displaySelectWhenDisabled) {
          return <DisabledValue selectValue={field.value} />
        }

        return (
          <Select
            {...field}
            {...props}
            label={props.withoutLabel ? undefined : props.label}
          />
        )
      }}
    />
  )
}

export function isMultiValue(
  x: SingleValue<SelectOption> | MultiValue<SelectOption>
): x is MultiValue<SelectOption> {
  return Array.isArray(x)
}

function DisabledValue({
  selectValue
}: {
  selectValue: SingleValue<SelectOption> | MultiValue<SelectOption>
}) {
  if (isMultiValue(selectValue)) {
    return (
      <Flex variant="row" wrap="wrap" align="center" gap={2}>
        {selectValue.map(({ value, label }) => (
          <SDisabledValueChip key={value}>{label}</SDisabledValueChip>
        ))}
      </Flex>
    )
  }

  return <TextBodyBold>{selectValue?.label}</TextBodyBold>
}
