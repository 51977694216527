import { css } from '@emotion/react'
import { Header } from 'components/header/Header'
import { Layout } from 'components/layout/Layout'
import { LINKS } from 'constants/links'
import { AuthRedirectType } from 'driverama-core/auth/next'
import { Flex } from 'driverama-core/components/Flex'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { GetStaticProps } from 'next'
import { CarDataTable } from 'sections/carTable/CarDataTable'
import { useCarDataTableFilters } from 'sections/carTable/CarDataTableFilters'
import { CarTableHeader } from 'sections/carTable/header/CarTableHeader'
import { useFiltersContext } from 'sections/filters/context/FiltersContext'
import { setFiltersForModule } from 'sections/filters/FilterHooks'
import { FilterModule } from 'sections/filters/Filters.utils'
import { MobileFilters } from 'sections/filters/mobileFilters/MobileFilters'
import { LeftMenu } from 'sections/leftMenu/LeftMenu'
import { Stats } from 'sections/stats/Stats'
import { getIntlProps } from 'utils/intl'

export default function Page() {
  const {
    state: { stockManager },
    dispatch
  } = useFiltersContext()

  const handleSearchInputSubmit = (value: string) => {
    dispatch(
      setFiltersForModule(FilterModule.MANAGER, {
        vinOrStockNo: !!value ? value : undefined
      })
    )
  }

  const carDataTableFilters = useCarDataTableFilters()

  return (
    <Layout
      header={
        <Header
          variant="transparent"
          defaultSearch={stockManager.filters?.vinOrStockNo}
          searchCar={handleSearchInputSubmit}
        />
      }
    >
      <MobileFilters
        searchKey="vinOrStockNo"
        filterModule={FilterModule.MANAGER}
        menuFilters={carDataTableFilters}
      />

      <Flex variant="row">
        <LeftMenu
          module={FilterModule.MANAGER}
          menuFilters={carDataTableFilters}
        />

        <Flex
          variant="column"
          css={css`
            width: calc(100vw - ${size(65)});

            @media ${media.lte('tablet')} {
              width: 100vw;
            }
          `}
        >
          <CarTableHeader />
          <Stats />
          <CarDataTable />
        </Flex>
      </Flex>
    </Layout>
  )
}

export const getStaticProps: GetStaticProps = async ctx => ({
  props: await getIntlProps(ctx, ['core'])
})

Page.auth = {
  type: AuthRedirectType.RequiresAuth,
  destination: LINKS.login
}
